import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const Pricing01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Precios' canonical={'precios/'} ld_json={{}} description={'Sin cláusulas de permanencia, cancela o cambia de plan en cualquier momento, Tenemos un plan GRATIS! por siempre'}/>

      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />

      <Header content={content['header']} menuJustify='space-between' />
      <Divider space='6' />
      <Pricing content={content['pricing']} />
      <Divider space='5' />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpagePricingBlockContent {
    allBlockContent(
      filter: { page: { in: ["pricing", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Pricing01
